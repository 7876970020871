<template>
  <SupportView />
</template>


<script>
export default {
  name: "SupportViewComp",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Customer Supports", path:"/customer-supports", isActive: false}, {text:"View", path:"", isActive: true}] 
    }
  },
  components: {
    SupportView: () => import("../../../src/components/customerSupport/SupportView"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>